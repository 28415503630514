import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import Dashboard from "./Dashboard";
import RegisterandLogin from "../pages/RegisterandLogin";
import NotFound from "../pages/NotFound";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import * as appJs from "../../js/app";

function App() {
  useEffect(() => {
    appJs.browserControl();
    return () => {
      //unmount calisir
    };
  }, []);

  return (
    <>
      {/* <InactiveHandler /> */}
      {/* <Nav /> */}
      {/* <Navigation /> */}
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<Dashboard />} />
        <Route path="register/*" element={<RegisterandLogin />} />
        <Route path="login/*" element={<RegisterandLogin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <FooterI /> */}
      {/* <Footer /> */}
    </>
  );
}

export default App;
