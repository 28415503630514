import * as actionTypes from "./actionTypes";
import initialState from "../reducers/initialState";
import alertify from "alertifyjs";

export function userInactive() {
  return {
    type: actionTypes.USER_INACTIVE,
  };
}

export function createBugReport(img, details, bug_type, token) {
  return async function (dispatch) {
    let url =
      initialState.url +
      "/users/create-bug-report?details=" +
      details +
      "&bug_type=" +
      bug_type;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          ...initialState.ngrok,
        },
        body: img,
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response;
      return result;
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function userDetailsSuccess(user) {
  return {
    type: actionTypes.GET_USER_DETAILS_SUCCESS,
    payload: user,
  };
}

export function userDetails(token) {
  return async function (dispatch) {
    let url = initialState.url + "/users/details";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(loginSuccess(response.status));
        } else {
          throw new Error(`Error! status: ${response.status}`);
        }
      }

      const result = await response.json();
      dispatch(userDetailsSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

//// LOGIN
export function loginSuccess(token) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: token,
  };
}

export function loginSuccessRememberMe(token) {
  return {
    type: actionTypes.LOGIN_SUCCESS_REMEMBER,
    payload: token,
  };
}

export function loginFailure(error) {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
  };
}

export function login(username, phone_no, mail, password, recaptcha, remember) {
  return async function (dispatch) {
    let url = initialState.url + "/users/login";

    const data = {
      username: username,
      phone_number: phone_no,
      email: mail,
      password: password,
      recaptcha_token: recaptcha,
    };
    const rememberMe = remember;

    // console.error(rememberMe);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (
        !response.ok &&
        response.status !== 404 &&
        response.status !== 401 &&
        response.status !== 403
      ) {
        throw new Error(`Error! status: ${response.status}`);
      }

      if (
        response.status === 404 ||
        response.status === 401 ||
        response.status === 403
      ) {
        return dispatch(loginSuccess(response.status));
      }

      const result = await response.json();
      if (rememberMe === true) return dispatch(loginSuccessRememberMe(result));
      else return dispatch(loginSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
      // return dispatch(loginFailure(error));
    }
  };
}

//// REGISTER
export function registerSuccess(token) {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: token,
  };
}

export function registerFailure(error) {
  return {
    type: actionTypes.REGISTER_FAILURE,
    payload: error,
  };
}

export function register(data) {
  return async function (dispatch) {
    let url = initialState.url + "/users/register";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      return dispatch(registerSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
      return dispatch(registerFailure(error));
    }
  };
}

// RESET PASSWORD

export function resetPasswordSuccess(status) {
  return {
    type: actionTypes.RESET_PASSWORD_CODE_SUCCESS,
    payload: status,
  };
}

export function resetPassword(email, phone_no, password) {
  return async function (dispatch) {
    let url = initialState.url + "/users/reset-password";

    const data = {
      email: email,
      phone_number: phone_no,
      password: password,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && response.status !== 400) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.status;
      return dispatch(resetPasswordSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function resetPasswordCodeSuccess(endTime) {
  return {
    type: actionTypes.RESET_PASSWORD_CODE_SUCCESS,
    payload: endTime,
  };
}

export function resetPasswordCode(email, phone_no) {
  return async function (dispatch) {
    let url = initialState.url + "/users/reset-password-code";

    const data = { email: email, phone_number: phone_no };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && response.status !== 404 && response.status !== 400) {
        throw new Error(`Error! status: ${response.status}`);
      }

      if (response.status === 404) {
        return dispatch(resetPasswordCodeSuccess(response.status));
      }

      const result = await response.json();

      return dispatch(resetPasswordCodeSuccess(result.remaining));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function resetPasswordCodeVerifySuccess(status, message) {
  return {
    type: actionTypes.RESET_PASSWORD_CODE_VERIFY_SUCCESS,
    payload: status,
    message: message,
  };
}

export function resetPasswordCodeVerify(email, phone_no, code) {
  return async function (dispatch) {
    let url = initialState.url + "/users/reset-password-code-verify";

    const data = { email: email, phone_number: phone_no, code: code };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && response.status !== 400 && response.status !== 404) {
        throw new Error(`Error! status: ${response.status}`);
      }
      // 400: kodun gecerlilik suresi doldu, yanlis kod
      // if (response.status === 400) {
      //   // return false;
      //   return dispatch(resetPasswordCodeVerifySuccess(response.status));
      // }

      const result = await response.status;
      let message;
      if (result !== 200) message = await response.json();

      // console.log(message);
      return dispatch(resetPasswordCodeVerifySuccess(result, message));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

////

export function verifyEmailSuccess(success) {
  return {
    type: actionTypes.VERIFY_EMAIL_SUCCESS,
    payload: success,
  };
}

export function verifyEmail(email, code) {
  return async function (dispatch) {
    let url = initialState.url + "/users/verify-email";

    const data = {
      email: email,
      code: code,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && response.status !== 400) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      return dispatch(verifyEmailSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function verifyEmailCodeSuccess(emailCode) {
  return {
    type: actionTypes.VERIFY_EMAIL_CODE_SUCCESS,
    payload: emailCode,
  };
}

export function verifyEmailCode(email) {
  return async function (dispatch) {
    let url = initialState.url + "/users/verify-email-code";

    const data = {
      email: email,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      return dispatch(verifyEmailCodeSuccess(result.remaining));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function verifyPhoneSuccess(success) {
  return {
    type: actionTypes.VERIFY_PHONE_SUCCESS,
    payload: success,
  };
}

export function verifyPhone(phone, code) {
  return async function (dispatch) {
    let url = initialState.url + "/users/verify-phone";

    const data = {
      phone_number: phone,
      code: code,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && response.status !== 400) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      return dispatch(verifyEmailSuccess(result));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function verifyPhoneCodeSuccess(phoneCode) {
  return {
    type: actionTypes.VERIFY_PHONE_CODE_SUCCESS,
    payload: phoneCode,
  };
}

export function verifyPhoneCode(phone_number) {
  return async function (dispatch) {
    let url = initialState.url + "/users/verify-phone-code";

    const data = {
      phone_number: phone_number,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok && response.status !== 400) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      dispatch(verifyPhoneCodeSuccess(result.remaining));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

//// CAN BE USE CONTROLS

export function phoneControl(use) {
  return {
    type: actionTypes.PHONE_CONTROL,
    payload: use,
  };
}

export function emailControl(use) {
  return {
    type: actionTypes.EMAIL_CONTROL,
    payload: use,
  };
}

export function usernameControl(use) {
  return {
    type: actionTypes.USERNAME_CONTROL,
    payload: use,
  };
}

export function canBeUsedControl(key, value) {
  return async function (dispatch) {
    let url = initialState.url + "/users/is-available?" + key + "=" + value;
    try {
      const response = await fetch(url, {
        headers: {
          ...initialState.ngrok,
        },
      });
      if (!response.ok && response.status === 409) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      switch (key) {
        case "phone_number":
          dispatch(phoneControl(result));
          break;
        case "email":
          dispatch(emailControl(result));
          break;
        case "username":
          dispatch(usernameControl(result));
          break;

        default:
          break;
      }
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

//// STATICS

export function sendFormObject(obj) {
  return {
    type: actionTypes.SEND_FORM_OBJECT,
    payload: obj,
  };
}

////

export function selectLeague(league) {
  return {
    type: actionTypes.SELECT_LEAGUE,
    payload: league,
  };
}

export function selectTeam(team) {
  return {
    type: actionTypes.SELECT_TEAM,
    payload: team,
  };
}
