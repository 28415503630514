import * as actionTypes from "./actionTypes";
import alertify from "alertifyjs";

export function getPhoneCodeSuccess(phoneCode) {
  return {
    type: actionTypes.GET_PHONE_CODES_SUCCESS,
    payload: phoneCode,
  };
}

export function getPhoneCode() {
  return async function (dispatch) {
    let url = "/api/phoneCode.json";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      const result2 = result.sort((a, b) => {
        const dialCodeA = parseInt(a.dial_code.replace(/\D/g, ""));
        const dialCodeB = parseInt(b.dial_code.replace(/\D/g, ""));
        return dialCodeA - dialCodeB;
      });
      dispatch(getPhoneCodeSuccess(result2));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function selectPhoneCode(phoneCode) {
  return {
    type: actionTypes.SELECT_PHONE_CODE,
    payload: phoneCode,
  };
}

// export function addMatchToPredictions(match) {
//   return async function (dispatch) {
//     try {
//       dispatch({
//         type: actionTypes.ADD_TO_PREDICTION,
//         payload: match,
//       });
//     } catch (error) {
//       alertify.error("Api Hatasi: " + error.message);
//     }
//   };
// }
