import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import initialState from "../../redux/reducers/initialState";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import * as AppJs from "../../js/app";
import MiniPreload from "../preload/MiniPreload";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    if (AppJs.tokenControl(this.props.token)) {
      this.props.actions.getBasket(this.props.token).then(() => {
        // console.log(this.props.basket.result.basket.length);
      });
      if (this.props.userDetails.length <= 0) {
        this.props.actions.getUserDetails(this.props.token).then(() => {
          // console.log(this.props.userDetails);
        });
      }
    }
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option) => {
    // this.props.actions.selectPhoneCode(option);

    this.setState({
      isOpen: false,
    });
  };

  render() {
    return (
      <nav>
        <div className="logo">
          <Link to="/">
            <figure>
              <img src="/img/1m1.svg" alt="logo" />
            </figure>
          </Link>
        </div>
        <div className="nav-content">
          <div className="pages">
            <Link to="/" className="b_1">
              Anasayfa
            </Link>
            <Link className="b_1">Nasıl Oynanır?</Link>
            {AppJs.tokenControl(this.props.token) ? (
              <Link to="prediction" className="b_1 prediction_link">
                Tahminlerim
                {this.props.basket.status === 200 &&
                this.props.basket.result.basket.length > 0 ? (
                  <span className="b_4">
                    {this.props.basket.result.basket.length}
                  </span>
                ) : (
                  <></>
                )}
              </Link>
            ) : (
              <></>
            )}
          </div>
          {!AppJs.tokenControl(this.props.token) ? (
            <div className="nav-btns">
              {/* <Link to="/register" className="b_2 background_btn">
                Kayıt Ol
              </Link> */}
              <Link to="/login" className="b_2 outline_btn">
                Giriş Yap
              </Link>
            </div>
          ) : (
            <></>
          )}
          <div className="select-langs">
            <div className="multilang-dropdown">
              <div
                className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
                onClick={(e) => this.toggleDropdown(e)}
              >
                <div className="content-drop">
                  <div className="img-drop">
                    <img
                      className="non_select"
                      src="/img/country/Flag_of_Turkey.svg"
                      alt="Flag"
                    />
                  </div>
                  <div className="txt">
                    <p className="b_1 non_select">TR</p>
                  </div>
                </div>
              </div>
              {/* {this.state.isOpen && (
                <ul className="options hide-scroll">
                  {this.props.phoneCode.length === 0 ? (
                    <MiniPreload />
                  ) : (
                    this.phoneCodes()
                  )}
                </ul>
              )} */}
            </div>
          </div>
          {AppJs.tokenControl(this.props.token) ? (
            <div className="user">
              <div className="notifications">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <path d="M40.62,28.34l-.87-.7A2,2,0,0,1,39,26.08V18A15,15,0,0,0,26.91,3.29a3,3,0,0,0-5.81,0A15,15,0,0,0,9,18v8.08a2,2,0,0,1-.75,1.56l-.87.7a9,9,0,0,0-3.38,7V37a4,4,0,0,0,4,4h8.26a8,8,0,0,0,15.47,0H40a4,4,0,0,0,4-4V35.36A9,9,0,0,0,40.62,28.34ZM24,43a4,4,0,0,1-3.44-2h6.89A4,4,0,0,1,24,43Zm16-6H8V35.36a5,5,0,0,1,1.88-3.9l.87-.7A6,6,0,0,0,13,26.08V18a11,11,0,0,1,22,0v8.08a6,6,0,0,0,2.25,4.69l.87.7A5,5,0,0,1,40,35.36Z" />
                  </svg>
                </figure>
              </div>
              <div className="profile">
                <figure>
                  <img
                    src={this.props.userDetails.profile_picture}
                    alt="ProfilePicture"
                  />
                </figure>
                <span className="point b_4">
                  {this.props.userDetails.total_points}
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </nav>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    // predictions: state.predictionsReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
