export default {
  // url: "http://88.247.145.163:8888",
  // url: "http://88.247.145.163:8000",
  url: "https://main-major-basilisk.ngrok-free.app",
  ngrok: { "ngrok-skip-browser-warning": "69420" },

  // COUPONS
  basket: [],

  // MATCHES
  leagueList: [],
  teamsList: [],
  matches: [],
  match: [],

  // USERS
  isActive: true,
  userDetails: [],
  selectedLeague: {
    id: 0,
    name: "Favori Liginizi Seçiniz",
    country: "1M1 Ligi",
    flag: "1m1.svg",
  },
  selectedTeam: {
    id: null,
    name: "Favori Takımınız",
    code: "1M1 Takımı",
    country: "",
    logo: "/img/1m1.svg",
    last_5_matches: "",
  },
  selectedPhoneCode: {
    flag: "/img/country/Flag_of_Turkey.svg",
    name: "Türkiye",
    dial_code: "+90",
    code: "TR",
  },
  register: {
    username: "",
    email: "",
    phone_number: "",
    password: "",
    campaign_notifications: "",
    recaptcha_token: "",
    fav_team: "",
  },
  canBeUsed: {
    phone: null,
    email: null,
    username: null,
  },
  verificationCodeEndTime: null,
  verifyCode: null,
  resetPasswordCodeEndTime: null,
  token:
    JSON.parse(localStorage.getItem("token")) ||
    JSON.parse(sessionStorage.getItem("token")) ||
    null,

  // STATICS
  phoneCode: {},
  // predictions: JSON.parse(localStorage.getItem("predictions")) || [],
};
