import React, { Component } from "react";
import initialState from "../../redux/reducers/initialState";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as matchActions from "../../redux/actions/matchActions";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class LeaguesDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.props.actions.getLeague();
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option) => {
    this.props.actions.selectLeague(option);
    this.setState({
      isOpen: false,
    });
    this.getTeams(option.id);
  };

  getTeams(id) {
    this.props.actions.getTeams(id);
    this.props.actions.selectTeam({
      id: null,
      name: "Favori Takımınız",
      code: "1M1 Takımı",
      country: "",
      logo: "/img/1m1.svg",
      last_5_matches: "",
    });
  }

  leagues() {
    return this.props.leagues.map((league) => (
      <li
        className="option"
        key={league.id}
        onClick={() => this.handleOptionClick(league)}
      >
        <div className="img-drop">
          <img
            className="non_select"
            src={"/media/" + league.flag}
            alt={league.id}
          />
        </div>
        <div className="txt">
          <h4 className="h_8 non_select">{league.name}</h4>
          <p className="b_4 non_select">{league.country}</p>
        </div>
      </li>
    ));
  }

  render() {
    return (
      <div className="leagues">
        <p className="b_2">Sevdiğiniz Ligi Seçiniz</p>
        <div className="custom-dropdown">
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            id="leagues"
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="img-drop">
                <img
                  className="non_select"
                  src={
                    "/media/" +
                    this.props.selectedLeague.flag
                  }
                  alt={this.props.selectedLeague.id}
                />
              </div>
              <div className="txt">
                <h4 className="h_8 non_select">
                  {this.props.selectedLeague.name}
                </h4>
                <p className="b_4 non_select">
                  {this.props.selectedLeague.country}
                </p>
              </div>
            </div>
            <div className={`arrow ${this.state.isOpen ? "open" : ""}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
              </svg>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {this.props.leagues.length === 0 ? (
                <MiniPreload />
              ) : (
                this.leagues()
              )}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leagues: state.leagueListReducer,
    selectedLeague: state.selectLeagueReducer,
    selectedTeam: state.selectTeamReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getLeague: bindActionCreators(matchActions.getLeagueList, dispatch),
      selectLeague: bindActionCreators(userActions.selectLeague, dispatch),
      selectTeam: bindActionCreators(userActions.selectTeam, dispatch),
      getTeams: bindActionCreators(matchActions.getLeagueTeams, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaguesDropdown);
