import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Homepage2 extends Component {
  render() {
    return (
      <div className="homepage-2">
        <div className="first" id="homepage">
          <div className="img">
            <div className="filter"></div>
            <img src="/img/homepage.png" alt="Banner" />
          </div>
          <div className="txt">
            <h4 className="h_6">MAÇ SONUÇLARINI TAHMİN EDİN</h4>
            <h4 className="h_6">
              YARIŞIN SONUNDA BÜYÜK ÖDÜLLERİN SAHİBİ OLUN!
            </h4>
            <h4 className="h_7">BİLENLERİ SAHAYA ALALIM</h4>
            <Link to="register" className="h_6 background_btn">
              ŞİMDİ KATILIN
            </Link>
          </div>
        </div>

        <div className="rewards" id="rewards">
          <div className="content">
            <h5 className="h_7">
              Sezon boyu sürecek sürükleyici ve heyecan dolu bir mücadele sizi
              bekliyor! Liglerdeki en iyi oyuncular arasına girerek, birbirinden
              değerli ödüllerin sahibi olabilirsiniz. Bu heyecan dolu rekabete
              katılın, yeteneklerinizi gösterin ve büyük ödüllere doğru adım
              atın!
            </h5>
            <Link to="coming-soon" className="background_btn h_8">
              Ödülleri Görüntüle
            </Link>
          </div>
          <figure>
            <img src="/img/rewards.png" alt="" />
          </figure>
        </div>

        {/* <div className="memberships" id="memberships">
          <div className="membership">
            <div className="hood">
              <h6 className="b_1">Süper Lig</h6>
              <p className="b_1">/ 400TL</p>
              <p className="b_3">(sezon boyu)</p>
            </div>
            <p className="b_3">
              Haftanın, Ayın ve Sezonun en iyisinin etkileyici hediyeler
              kazanabileceği ve reklam içermeyen paket ile doyasıya yarışın.
            </p>
            <Link to="coming-soon" className="b_1 background-btn">
              Paketi Seç
            </Link>
          </div>
          <div className="membership">
            <div className="hood">
              <h6 className="b_1">Avrupa Ligi</h6>
              <p className="b_1">/ 600TL</p>
              <p className="b_3">(sezon boyu)</p>
            </div>
            <p className="b_3">
              Daha fazla kupon yapma hakkı ve daha büyük ödüller. Avrupa
              Ligi'nde her şey daha çok.
            </p>
            <Link to="coming-soon" className="b_1 background-btn">
              Paketi Seç
            </Link>
          </div>
          <div className="membership">
            <div className="hood">
              <h6 className="b_1">Şampiyonlar Ligi</h6>
              <p className="b_1">/ 900TL</p>
              <p className="b_3">(sezon boyu)</p>
            </div>
            <p className="b_3">
              Daha büyük ödüller, sınırsız eğlence ve diğer paketlerden daha
              fazla fırsatla ayrıcalıkların zirvesine ulaşın!
            </p>
            <Link to="coming-soon" className="b_1 background-btn">
              Paketi Seç
            </Link>
          </div>
          <div className="free-plan">
            <div className="membership ">
              <div className="hood">
                <h6 className="b_1">Birinci Lig</h6>
                <p className="b_1">/ Ücretsiz</p>
              </div>
              <p className="b_3">
                Haftanın, Ayın ve Sezonun en iyisinin ufak hediyeler
                kazanabileceği reklam içerikli ücretsiz paket ile
                heyecana ortak olun.
              </p>
              <Link to="coming-soon" className="b_1 background-btn">
                Paketi Seç
              </Link>
            </div>
            <div className="how-to-play">
              <h6 className="b_1">
                Nasıl Oynanır Sayfamızdan Üyeliklerimizin Avantajlarını
                Öğrenebilirsiniz
              </h6>
              <Link to="coming-soon" className="b_1 background-btn">
                Sayfaya Git
              </Link>
            </div>
          </div>
        </div> */}

        <div className="how-to" id="how-to">
          <div className="content">
            <figure>
              <img src="/img/login.png" alt="" />
            </figure>
            <h4 className="h_8">Kayıt Ol & Giriş Yap</h4>
          </div>
          <img src="/img/arrow.png" alt="" />
          <div className="content">
            <figure>
              <img src="/img/5446976-200.png" alt="" />
            </figure>
            <h4 className="h_8">Lig Maçlarına Bak ve Tahminini Oluştur</h4>
          </div>
          <img src="/img/arrow.png" alt="" />
          <div className="content">
            <figure>
              <img src="/img/score.png" alt="" />
            </figure>
            <h4 className="h_8">Kazanan / Kaybeden Seç veya Skor Tahmin Et</h4>
          </div>
          <img src="/img/arrow.png" alt="" />
          <div className="content">
            <figure>
              <img src="/img/win.png" alt="" />
            </figure>
            <h4 className="h_8">Maç Sonuçlarını Bekle ve Puanları Kap!</h4>
          </div>
        </div>
      </div>
    );
  }
}
