import React, { Component } from "react";

class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      suggestions: [],
    };

    this.domains = ["gmail.com", "hotmail.com", "yahoo.com", "outlook.com"];
  }

  handleChange = (e) => {
    const value = e.target.value;
    const atIndex = value.indexOf("@");

    this.setState({ email: value });

    // Kullanıcı "@" yazdıysa ve bir domain seçmemişse öneriler göster
    if (atIndex > -1) {
      const inputDomain = value.slice(atIndex + 1);
      const filteredDomains = this.domains.filter((domain) =>
        domain.startsWith(inputDomain)
      );
      const suggestions = filteredDomains.map(
        (domain) => value.slice(0, atIndex + 1) + domain
      );

      this.setState({ suggestions });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  handleSuggestionClick = (suggestion) => {
    this.setState({
      email: suggestion,
      suggestions: [],
    });
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <input
          type="text"
          value={this.state.email}
          onChange={this.handleChange}
          placeholder="E-posta adresinizi girin"
        />
        {this.state.suggestions.length > 0 && (
          <ul
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              background: "white",
              border: "1px solid #ccc",
              listStyle: "none",
              padding: 0,
              margin: 0,
              zIndex: 1000,
            }}
          >
            {this.state.suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => this.handleSuggestionClick(suggestion)}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default EmailInput;
