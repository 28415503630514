import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import PhoneDropdown from "../dropdowns/PhoneDropdown";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReCAPTCHA from "react-google-recaptcha";
import * as userActions from "../../redux/actions/userActions";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoad: false,
      isRedirect: false,
      form: {
        phone: "",
        last_phone: "",
        mail: "",
        username: "",
        password: "",
        recaptcha: "",
        remember: false,
      },
      isWrong: {
        phone: null,
        mail: null,
        username: null,
        password: null,
        recaptcha: null,
      },
      isWhere: {
        phone: true,
        mail: false,
        username: false,
      },
    };
  }

  componentDidMount() {
    document.getElementsByClassName("main-hood")[0].style.display =
      "inline-block";
    switch (window.location.hash) {
      case "#email":
        this.setState((prevState) => ({
          isWhere: {
            ...prevState.isWhere,
            phone: false,
            mail: true,
            username: false,
          },
        }));
        break;
      case "#phone":
        this.setState((prevState) => ({
          isWhere: {
            ...prevState.isWhere,
            phone: true,
            mail: false,
            username: false,
          },
        }));
        break;
      case "#username":
        this.setState((prevState) => ({
          isWhere: {
            ...prevState.isWhere,
            phone: false,
            mail: false,
            username: true,
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          isWhere: {
            ...prevState.isWhere,
            phone: true,
            mail: false,
            username: false,
          },
        }));
        break;
    }
  }

  changeInput = (e) => {
    this.setState((prevState) => ({
      // isAuthenticated: false,
      form: {
        ...prevState.form,
        phone: "",
        last_phone: "",
        mail: "",
        username: "",
      },
      isWrong: {
        ...prevState.isWrong,
        phone: null,
        mail: null,
        username: null,
      },
    }));
    if (e.currentTarget.className.includes("phone-no-link")) {
      this.setState((prevState) => ({
        isWhere: {
          ...prevState.isWhere,
          phone: true,
          mail: false,
          username: false,
        },
      }));
    } else if (e.currentTarget.className.includes("mail-link")) {
      this.setState((prevState) => ({
        isWhere: {
          ...prevState.isWhere,
          phone: false,
          mail: true,
          username: false,
        },
      }));
    } else if (e.currentTarget.className.includes("username-link")) {
      this.setState((prevState) => ({
        isWhere: {
          ...prevState.isWhere,
          phone: false,
          mail: false,
          username: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        isWhere: {
          ...prevState.isWhere,
          phone: true,
          mail: false,
          username: false,
        },
      }));
    }
  };

  canBeUsedControl = async (key, value) => {
    await this.props.actions.canBeUsedControl(key, value);
  };

  phoneControl(value) {
    const phone =
      !value.includes("_") && value !== ""
        ? this.props.selectedPhoneCode.dial_code + value
        : null;
    const phone2 = phone !== null ? phone.replace(/[+\s]/g, "") : null;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        last_phone: phone2,
      },
    }));
  }

  handleChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.value,
        },
      }),
      () => {
        switch (e.target.name) {
          case "phone":
            this.phoneControl(e.target.value);
            break;
          // case "mail":
          //   this.mailControl(e.target.value);
          //   break;
          // case "username":
          //   this.usernameControl(e.target.value);
          //   break;
          // case "password":
          //   this.passwordControl(e.target.value);
          //   this.passwordAgainControl();
          //   break;
          // case "password_again":
          //   this.passwordAgainControl(e.target.value);
          //   break;
          default:
            break;
        }
      }
    );
  };

  handleCheckbox = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.checked,
        },
      }),
      () => {}
    );
  };

  handleFocus = (e) => {
    // switch (e.currentTarget.id) {
    //   case "mail":
    //     this.setState({
    //       focus: {
    //         mail: true,
    //       },
    //     });
    //     break;
    //   case "username":
    //     this.setState({
    //       focus: {
    //         username: true,
    //       },
    //     });
    //     break;
    //   case "password":
    //     this.setState({
    //       focus: {
    //         password: true,
    //       },
    //     });
    //     break;
    //   case "password-again":
    //     this.setState({
    //       focus: {
    //         password_again: true,
    //       },
    //     });
    //     break;
    //   default:
    //     this.setState({
    //       focus: {
    //         mail: false,
    //         username: false,
    //         password: false,
    //         password_again: false,
    //       },
    //     });
    //     break;
    // }
  };

  handleBlur = (e) => {
    // this.setState({
    //   focus: {
    //     phone: false,
    //     mail: false,
    //     username: false,
    //     password: false,
    //     password_again: false,
    //   },
    // });
    // console.log(this.state.form.recaptcha);
    switch (e.currentTarget.id) {
      case "phone":
        this.canBeUsedControl("phone_number", this.state.form.last_phone).then(
          () => {
            this.setState((prevState) => ({
              isWrong: {
                ...prevState.isWrong,
                phone: this.props.canBeUse.phone ? true : false,
              },
            }));
          }
        );
        break;
      case "mail":
        this.canBeUsedControl("email", this.state.form.mail).then(() => {
          this.setState((prevState) => ({
            isWrong: {
              ...prevState.isWrong,
              mail: this.props.canBeUse.email ? true : false,
            },
          }));
        });
        break;
      case "username":
        this.canBeUsedControl("username", this.state.form.username).then(() => {
          this.setState(
            (prevState) => ({
              isWrong: {
                ...prevState.isWrong,
                username: this.props.canBeUse.username ? true : false,
              },
            }),
            () => {
              // Yeni state güncellendikten sonra çalışacak
            }
          );
        });
        break;
      // case "password":
      //   this.setState(
      //     (prevState) => ({
      //       isWrong: {
      //         ...prevState.isWrong,
      //         password:
      //           this.state.terms.password.term1 &&
      //           this.state.terms.password.term2 &&
      //           this.state.terms.password.term3
      //             ? false
      //             : true,
      //       },
      //     }),
      //     () => {
      //       // Yeni state güncellendikten sonra çalışacak
      //     }
      //   );
      //   break;
      // case "password-again":
      //   this.setState(
      //     (prevState) => ({
      //       isWrong: {
      //         ...prevState.isWrong,
      //         password_again: this.state.terms.password_again.term1
      //           ? false
      //           : true,
      //       },
      //     }),
      //     () => {
      //       // Yeni state güncellendikten sonra çalışacak
      //     }
      //   );
      //   break;
      default:
        break;
    }
  };

  send = (e) => {
    e.preventDefault();
    // console.log(this.state.form.recaptcha);

    if (
      ((!this.state.isWrong.phone && this.state.isWrong.phone !== null) ||
        (!this.state.isWrong.mail && this.state.isWrong.mail !== null) ||
        (!this.state.isWrong.username &&
          this.state.isWrong.username !== null)) &&
      !this.state.isWrong.recaptcha &&
      this.state.isWrong.recaptcha !== null
    ) {
      this.setState({
        btnLoad: true,
      });
      this.props.actions
        .login(
          this.state.form.username,
          this.state.form.last_phone,
          this.state.form.mail,
          this.state.form.password,
          this.state.form.recaptcha,
          this.state.form.remember
        )
        .then(() => {
          this.setState({
            btnLoad: false,
          });
          if (this.props.token === 401) {
            window.grecaptcha.reset();
            this.setState(
              (prevState) => ({
                form: {
                  ...prevState.form,
                  recaptcha: "",
                },
                isWrong: {
                  ...prevState.isWrong,
                  password: true,
                },
              }),
              () => {}
            );
          } else if (this.props.token !== 500 || this.props.token !== 404) {
            this.setState({ isRedirect: true });
          }
        });
    } else {
      // console.error("Giriş Yapılamaz");
    }
  };

  render() {
    if (this.state.isRedirect) {
      return <Navigate to="/" />;
    }

    return (
      <div className="form">
        <div className="third-party-register">
          <Link className="third_party_btns btn_apple">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
            {/* <p className="b_4">Apple ile Giriş Yap</p> */}
          </Link>
          <Link className="third_party_btns btn_google">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30">
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.5 12.2726V18.0817H23.5727C23.2182 19.9499 22.1545 21.5318 20.559 22.5954L25.4272 26.3727C28.2635 23.7546 29.8999 19.9091 29.8999 15.341C29.8999 14.2774 29.8045 13.2545 29.6272 12.2728L15.5 12.2726Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.09376 17.8551L5.9958 18.6955L2.10938 21.7228C4.57755 26.6182 9.63627 30 15.4999 30C19.5498 30 22.9452 28.6637 25.427 26.3728L20.5589 22.5955C19.2225 23.4955 17.518 24.041 15.4999 24.041C11.5999 24.041 8.28634 21.4092 7.09989 17.8637L7.09376 17.8551Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M2.10897 8.27728C1.0863 10.2954 0.5 12.5727 0.5 14.9999C0.5 17.4271 1.0863 19.7045 2.10897 21.7226C2.10897 21.7361 7.09997 17.8498 7.09997 17.8498C6.79997 16.9498 6.62265 15.9953 6.62265 14.9998C6.62265 14.0042 6.79997 13.0497 7.09997 12.1497L2.10897 8.27728Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.5002 5.97272C17.7093 5.97272 19.6729 6.73634 21.2411 8.20909L25.5365 3.91369C22.9319 1.48646 19.5502 0 15.5002 0C9.63657 0 4.57755 3.36818 2.10938 8.27728L7.10022 12.15C8.28651 8.60452 11.6002 5.97272 15.5002 5.97272Z"
              />
            </svg>
            {/* <p className="b_4">Google ile Giriş Yap</p> */}
          </Link>
          <Link className="third_party_btns btn_facebook">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
            </svg>
            {/* <p className="b_4">Facebook ile Giriş Yap</p> */}
          </Link>
        </div>
        <div className="divider">
          <hr />
          <p className="b_2">E posta ile Giriş Yap</p>
          <hr />
        </div>
        <form onSubmit={this.send}>
          <div className="phone-mail-username">
            <div className="links">
              <Link
                to="#phone"
                className={
                  "phone-no-link b_3 " +
                  (this.state.isWhere.phone ? "active" : "")
                }
                onClick={this.changeInput}
              >
                Telefon
              </Link>
              <Link
                to="#email"
                className={
                  "mail-link b_3 " + (this.state.isWhere.mail ? "active" : "")
                }
                onClick={this.changeInput}
              >
                E-posta
              </Link>
              <Link
                to="#username"
                className={
                  "username-link b_3 " +
                  (this.state.isWhere.username ? "active" : "")
                }
                onClick={this.changeInput}
              >
                Kullanıcı Adı
              </Link>
            </div>

            <div
              className="phone"
              style={
                this.state.isWhere.phone
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div
                className={
                  "phone-input " +
                  (this.state.form.phone !== ""
                    ? this.state.isWrong.phone
                      ? "wrong"
                      : ""
                    : "")
                }
              >
                <PhoneDropdown />
                <MaskedInput
                  mask={[
                    /[1-9]/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                  ]}
                  name="phone"
                  id="phone"
                  className={"b_1 "}
                  placeholder="555 333 44 44"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  value={this.state.form.phone}
                  onChange={this.handleChange}
                />
                <div
                  className={
                    "terms non_select " +
                    (this.state.form.last_phone !== ""
                      ? this.state.isWrong.phone &&
                        this.state.isWrong.phone !== null
                        ? "active"
                        : ""
                      : "")
                  }
                >
                  <div className="term">
                    <p className={"b_4 wrong"}>
                      Bu Telefon Numarası Kayıtlı Değil.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mail"
              style={
                this.state.isWhere.mail
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <input
                type="email"
                id="mail"
                name="mail"
                className={
                  "b_1 input " +
                  (this.state.form.mail !== ""
                    ? this.state.isWrong.mail
                      ? "wrong"
                      : ""
                    : "")
                }
                placeholder="********@gmail.com"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.mail}
                onChange={this.handleChange}
              />
              <div
                className={
                  "terms non_select " +
                  (this.state.form.mail !== ""
                    ? this.state.isWrong.mail &&
                      this.state.isWrong.mail !== null
                      ? "active"
                      : ""
                    : "")
                }
              >
                <div className="term">
                  <p className={"b_4 wrong"}>
                    Bu E-posta Adresi Kayıtlı Değil.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="username"
              style={
                this.state.isWhere.username
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <input
                type="text"
                id="username"
                maxLength="16"
                className={
                  "b_1 input " +
                  (this.state.form.username !== ""
                    ? this.state.isWrong.username
                      ? "wrong"
                      : ""
                    : "")
                }
                placeholder="user1234"
                name="username"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.username}
                onChange={(e) => {
                  if (
                    e.target.value.length <= 16 &&
                    !e.target.value.includes(" ")
                  ) {
                    this.handleChange(e);
                  }
                }}
              />
              <div
                className={
                  "terms non_select " +
                  (this.state.form.username !== ""
                    ? this.state.isWrong.username &&
                      this.state.isWrong.username !== null
                      ? "active"
                      : ""
                    : "")
                }
              >
                <div className="term">
                  <p className={"b_4 wrong"}>Bu Kullanıcı Adı Kayıtlı Değil.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="password">
            <label htmlFor="password" className="b_2">
              Şifre
            </label>
            <input
              type="password"
              id="password"
              className={
                "b_1 input " +
                (this.state.isWrong.password &&
                this.state.isWrong.password !== null
                  ? "wrong"
                  : "")
              }
              placeholder="********"
              name="password"
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              value={this.state.form.password}
              onChange={this.handleChange}
            />
            <div
              className={
                "terms non_select " +
                (this.state.isWrong.password &&
                this.state.isWrong.password !== null
                  ? "active"
                  : "")
              }
            >
              <div className="term">
                <p className={"b_4 wrong"}>Girdiğiniz Şifre Yanlıştır.</p>
              </div>
            </div>
          </div>

          <div className="remember-forget">
            <div className="remember-me">
              <label
                htmlFor="remember-me"
                className="custom-checkbox b_2 non_select"
              >
                Beni Hatırla
                <input
                  type="checkbox"
                  name="remember"
                  id="remember-me"
                  value={this.state.form.remember}
                  onChange={this.handleCheckbox}
                />
                <span className="checkmark">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </span>
              </label>
            </div>
            <Link to="forgot_password" className="b_2">
              Şifremi Unuttum
            </Link>
          </div>

          <div
            className={
              "recaptcha " +
              ((!this.state.isWrong.phone &&
                this.state.isWrong.phone !== null) ||
              (!this.state.isWrong.mail && this.state.isWrong.mail !== null) ||
              (!this.state.isWrong.username &&
                this.state.isWrong.username !== null)
                ? "active "
                : "")
            }
          >
            <ReCAPTCHA
              sitekey="6LckKIEqAAAAAJHKnh4otAd_B0XtexJbNAklNAi1"
              onChange={(val) => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    recaptcha: val,
                  },
                  isWrong: {
                    ...prevState.isWrong,
                    recaptcha: !val,
                  },
                }));
              }}
            />
          </div>

          <button
            type="submit"
            className="background_btn"
            disabled={this.state.btnLoad ? true : false}
            data-loader={this.state.btnLoad ? true : false}
          >
            <span className="btn-loader"></span>
            <h4 className="b_1">Giriş Yap</h4>
          </button>
          <Link to="/register" className="no-account b_1">
            Hesabın Yoksa Kayıt Ol
          </Link>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      // verifyEmailCode: bindActionCreators(
      //   userActions.verifyEmailCode,
      //   dispatch
      // ),
      // verifyPhoneCode: bindActionCreators(
      //   userActions.verifyPhoneCode,
      //   dispatch
      // ),
      // sendFormObject: bindActionCreators(userActions.sendFormObject, dispatch),
      login: bindActionCreators(userActions.login, dispatch),
      canBeUsedControl: (key, value) =>
        dispatch(userActions.canBeUsedControl(key, value)),
    },
  };
}

function mapStateToProps(state) {
  return {
    canBeUse: state.canBeUsedReducer,
    selectedPhoneCode: state.selectPhoneCodeReducer,
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
