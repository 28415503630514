import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import * as appJs from "../../js/app";
import MiniPreload from "../preload/MiniPreload";

class Prediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
    };
  }

  componentDidMount() {
    this.tokenControl();
    if (appJs.tokenControl(this.props.token)) {
      this.props.actions.getBasket(this.props.token).then(() => {
        // console.log(this.props.basket);
      });
      this.props.actions.getUserDetails(this.props.token).then(() => {
        // console.log(this.props.userDetails);
      });
    }
  }

  tokenControl() {
    if (appJs.tokenControl(this.props.token)) {
      this.setState({
        isRedirect: false,
      });
    } else {
      this.setState({
        isRedirect: true,
      });
    }
  }

  deleteToBasket = (matchId) => {
    this.props.actions
      .deleteToBasket({ match_id: matchId }, this.props.token)
      .then(() => {
        window.location.reload();
      });
  };

  createPrediction = () => {
    this.props.actions.createPrediction(this.props.token).then((e) => {
      if (e.status === 200) {
        window.location.reload();
      }
    });
  };

  render() {
    if (this.state.isRedirect) return <Navigate to="/login" />;

    return this.props.basket.status === 200 ? (
      <div className="prediction_page">
        <h5 className="hood h_5">Tahmin Paketi</h5>
        {this.props.basket.result.basket.length <= 0 ? (
          <p className="hood h_8">
            Bu sayfada tahmin ettiğiniz maç sonuçlarını görebileceksiniz. Tahmin
            yaparak puan kazanabilir ve ödülleri kapabilirsiniz!
          </p>
        ) : (
          <></>
        )}
        <div
          className="prediction_page_content"
          style={
            this.props.basket.result.basket.length <= 0
              ? { alignItems: "center" }
              : {}
          }
        >
          <div className="right_to_predictions">
            <div className="hood">
              <figure>
                <img src="/img/5446976-200.png" alt="Prediction Img" />
              </figure>
              <h6 className="h_8">Kalan Paket Hakkı</h6>
            </div>
            <p className="remaining_prediction h_8">
              {this.props.userDetails.coupons_right} / Hafta
            </p>
          </div>
          <div className="predictions_and_settings">
            <div className="predictions">
              {this.props.basket.result.basket.length > 0 ? (
                this.props.basket.result.basket.map((prediction, i) => (
                  <div className="prediction" key={i}>
                    <div className="prediction_content">
                      <h5 className="h_5">
                        {appJs.matchFormatDate(prediction.date)}
                      </h5>
                      <div className="teams">
                        <div className="team_names left">
                          <h6 className="team h_8">
                            {prediction.home_short_name}
                          </h6>
                          <span className="vs">VS</span>
                          <h6 className="team h_8 right">
                            {prediction.away_short_name}
                          </h6>
                        </div>
                        <div className="teams_last_matches">
                          <div className="team_last_matches left">
                            <p className="b_4">Son 5 Maç</p>
                            <div className="data">
                              {appJs
                                .formatLastMatch(
                                  prediction.home_last_5_matches !== null
                                    ? prediction.home_last_5_matches
                                    : "NNNNN"
                                )
                                .map((match, i) => (
                                  <span
                                    className={"match_info " + match}
                                    key={i}
                                  >
                                    <span className="modal non_select b_4">
                                      {match === "W"
                                        ? "Kazanan"
                                        : match === "L"
                                        ? "Kaybeden"
                                        : match === "D"
                                        ? "Berabere"
                                        : "Maç Verisi Yok"}
                                    </span>
                                  </span>
                                ))}
                            </div>
                          </div>
                          <div className="team_last_matches right">
                            <p className="b_4">Son 5 Maç</p>
                            <div className="data">
                              {appJs
                                .formatLastMatch(
                                  prediction.away_last_5_matches !== null
                                    ? prediction.away_last_5_matches
                                    : "NNNNN"
                                )
                                .map((match, i) => (
                                  <span
                                    className={"match_info " + match}
                                    key={i}
                                  >
                                    <span className="modal non_select b_4">
                                      {match === "W"
                                        ? "Kazanan"
                                        : match === "L"
                                        ? "Kaybeden"
                                        : match === "D"
                                        ? "Berabere"
                                        : "Maç Verisi Yok"}
                                    </span>
                                  </span>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="prediction_select">
                      <p className="h_8">
                        Tahminin :
                        <span>
                          {appJs.formatMatchSelection(
                            prediction.bet_type,
                            prediction.home_score,
                            prediction.away_score
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="delete">
                      <button
                        className="background_btn"
                        onClick={() => this.deleteToBasket(prediction.match_id)}
                      >
                        <span className="btn-loader"></span>
                        <p className="b_4">Sil</p>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_prediction">
                  <h6 className="h_7">Henüz Tahminde Bulunmadınız!</h6>
                  <Link to="/" className="b_3 background_btn">
                    Tahmin Yap!
                  </Link>
                </div>
              )}
              {this.props.basket.result.basket.length > 0 ? (
                <button
                  className="background_btn"
                  onClick={this.createPrediction}
                >
                  <span className="btn-loader"></span>
                  <p className="h_8">Tahminleri Paketle</p>
                </button>
              ) : (
                <></>
              )}
            </div>
            {this.props.basket.result.basket.length > 0 ? (
              <div className="settings">
                <span>
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" />
                    </svg>
                  </figure>
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      deleteToBasket: bindActionCreators(
        predictionActions.deleteToBasket,
        dispatch
      ),
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
      createPrediction: async (token) => {
        return await dispatch(predictionActions.createPrediction(token));
      },
    },
  };
}

function mapStateToProps(state) {
  return {
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Prediction);
