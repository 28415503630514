import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import { Link, Navigate } from "react-router-dom";
import PhoneDropdown from "../../dropdowns/PhoneDropdown";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../redux/actions/userActions";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      btnLoad: false,
      isPhone: true,
      form: {
        phone: "",
        last_phone: "",
        mail: "",
      },
      isWrong: {
        phone: null,
        mail: null,
      },
    };
  }

  componentDidMount() {
    // document.getElementsByClassName("main-hood")[0].style.display = "none";
    switch (window.location.hash) {
      case "#email":
        this.setState(() => ({
          isPhone: false,
        }));
        break;
      case "#phone":
        this.setState(() => ({
          isPhone: true,
        }));
        break;
      default:
        this.setState(() => ({
          isPhone: true,
        }));
        break;
    }
  }

  changeInput = (e) => {
    this.setState((prevState) => ({
      isRedirect: false,
      form: {
        ...prevState.form,
        phone: "",
        last_phone: "",
        mail: "",
      },
      isWrong: {
        ...prevState.isWrong,
        phone: null,
        mail: null,
      },
    }));
    if (!e.currentTarget.classList.contains("active")) {
      this.setState((prevState) => ({
        isPhone: !prevState.isPhone,
      }));
    }
  };

  phoneControl(value) {
    const phone =
      !value.includes("_") && value !== ""
        ? this.props.selectedPhoneCode.dial_code + value
        : null;
    const phone2 = phone !== null ? phone.replace(/[+\s]/g, "") : null;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        last_phone: phone2,
      },
    }));
  }

  handleChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.value,
        },
      }),
      () => {
        switch (e.target.name) {
          case "phone":
            this.phoneControl(e.target.value);
            break;
          // case "mail":
          //   this.mailControl(e.target.value);
          //   break;
          // case "username":
          //   this.usernameControl(e.target.value);
          //   break;
          // case "password":
          //   this.passwordControl(e.target.value);
          //   this.passwordAgainControl();
          //   break;
          // case "password_again":
          //   this.passwordAgainControl(e.target.value);
          //   break;
          default:
            break;
        }
      }
    );
  };

  handleFocus = (e) => {
    // switch (e.currentTarget.id) {
    //   case "mail":
    //     this.setState({
    //       focus: {
    //         mail: true,
    //       },
    //     });
    //     break;
    //   case "username":
    //     this.setState({
    //       focus: {
    //         username: true,
    //       },
    //     });
    //     break;
    //   case "password":
    //     this.setState({
    //       focus: {
    //         password: true,
    //       },
    //     });
    //     break;
    //   case "password-again":
    //     this.setState({
    //       focus: {
    //         password_again: true,
    //       },
    //     });
    //     break;
    //   default:
    //     this.setState({
    //       focus: {
    //         mail: false,
    //         username: false,
    //         password: false,
    //         password_again: false,
    //       },
    //     });
    //     break;
    // }
  };

  handleBlur = (e) => {
    // this.setState({
    //   focus: {
    //     phone: false,
    //     mail: false,
    //     username: false,
    //     password: false,
    //     password_again: false,
    //   },
    // });
    // switch (e.currentTarget.id) {
    //   case "phone":
    //     this.canBeUsedControl("phone_number", this.state.form.last_phone).then(
    //       () => {
    //         this.setState((prevState) => ({
    //           isWrong: {
    //             ...prevState.isWrong,
    //             phone: this.props.canBeUse.phone ? true : false,
    //           },
    //         }));
    //       }
    //     );
    //     break;
    //   case "mail":
    //     console.log(this.state.form.recaptcha);
    //     this.canBeUsedControl("email", this.state.form.mail).then(() => {
    //       this.setState((prevState) => ({
    //         isWrong: {
    //           ...prevState.isWrong,
    //           mail: this.props.canBeUse.email ? true : false,
    //         },
    //       }));
    //     });
    //     break;
    //   case "username":
    //     this.canBeUsedControl("username", this.state.form.username).then(() => {
    //       this.setState(
    //         (prevState) => ({
    //           isWrong: {
    //             ...prevState.isWrong,
    //             username: this.props.canBeUse.username ? true : false,
    //           },
    //         }),
    //         () => {
    //           // Yeni state güncellendikten sonra çalışacak
    //         }
    //       );
    //     });
    //     break;
    //   default:
    //     break;
    // }
  };

  send = (e) => {
    e.preventDefault();
    this.setState({ btnLoad: true });
    if (this.state.form.last_phone !== "" || this.state.form.mail !== "") {
      this.setState((prevState) => ({
        isWrong: { ...prevState.isWrong, phone: false, mail: false },
      }));
      this.props.actions
        .sendForm(this.state.form.mail, this.state.form.last_phone)
        .then(() => {
          this.setState({ btnLoad: false });
          if (this.props.endTime === 404) {
            this.setState((prevState) => ({
              isWrong: { ...prevState.isWrong, phone: true, mail: true },
            }));
          } else {
            // console.log(this.props.endTime);
            this.setState({ isRedirect: true });
          }
        });
    } else {
      this.setState({ btnLoad: false });
      if (this.state.form.last_phone === "") {
        this.setState((prevState) => ({
          isWrong: { ...prevState.isWrong, phone: true },
        }));
      }
      if (this.state.form.mail === "") {
        this.setState((prevState) => ({
          isWrong: { ...prevState.isWrong, mail: true },
        }));
      }
    }
  };

  render() {
    if (this.state.isRedirect)
      return <Navigate to="verify_code" state={{ form: this.state.form }} />;

    return (
      <form onSubmit={this.send}>
        <p className="b_1 desc">
          Şifrenizi mi unuttunuz? Hesabınıza yeniden erişim sağlamak için
          sıfırlama adımlarını takip edin.
        </p>
        <div className="phone-mail">
          <div className="links">
            <Link
              to="#phone"
              className={"b_3 " + (this.state.isPhone ? "active" : "")}
              onClick={this.changeInput}
            >
              Telefon
            </Link>
            <Link
              to="#email"
              className={"b_3 " + (this.state.isPhone ? "" : "active")}
              onClick={this.changeInput}
            >
              E-posta
            </Link>
          </div>
          <div
            className="phone"
            style={
              this.state.isPhone ? { display: "block" } : { display: "none" }
            }
          >
            <div
              className={
                "phone-input " + (this.state.isWrong.phone ? "wrong" : "")
              }
            >
              <PhoneDropdown />
              <MaskedInput
                mask={[
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                ]}
                name="phone"
                id="phone"
                className={"b_2 "}
                placeholder="555 333 44 44"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.phone}
                onChange={this.handleChange}
              />
              <div
                className={
                  "terms non_select " +
                  (this.state.isWrong.phone ? "active" : "")
                }
              >
                <div className="term">
                  <p className={"b_4 wrong"}>
                    Bu Telefon Numarası Kayıtlı Değil.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mail"
            style={
              this.state.isPhone ? { display: "none" } : { display: "block" }
            }
          >
            <input
              type="email"
              id="mail"
              name="mail"
              className={
                "b_2 input " + (this.state.isWrong.mail ? "wrong" : "")
              }
              placeholder="********@gmail.com"
              // autoComplete="on"
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              value={this.state.form.mail}
              onChange={this.handleChange}
            />
            <div
              className={
                "terms non_select " + (this.state.isWrong.mail ? "active" : "")
              }
            >
              <div className="term">
                <p className={"b_4 wrong"}>Bu E-posta Adresi Kayıtlı Değil.</p>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="background_btn"
          disabled={this.state.btnLoad ? true : false}
          data-loader={this.state.btnLoad ? true : false}
        >
          <span className="btn-loader"></span>
          <h4 className="b_1">Devam Et</h4>
        </button>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      // verifyEmailCode: bindActionCreators(
      //   userActions.verifyEmailCode,
      //   dispatch
      // ),
      // verifyPhoneCode: bindActionCreators(
      //   userActions.verifyPhoneCode,
      //   dispatch
      // ),
      // sendFormObject: bindActionCreators(userActions.sendFormObject, dispatch),
      // login: bindActionCreators(userActions.login, dispatch),
      sendForm: bindActionCreators(userActions.resetPasswordCode, dispatch),
      // canBeUsedControl: (key, value) =>
      //   dispatch(userActions.canBeUsedControl(key, value)),
    },
  };
}

function mapStateToProps(state) {
  return {
    // canBeUse: state.canBeUsedReducer,
    selectedPhoneCode: state.selectPhoneCodeReducer,
    endTime: state.resetPasswordCodeReducer,
    // token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
