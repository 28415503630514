import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function sendFormObjectReducer(
  state = initialState.register,
  action
) {
  switch (action.type) {
    case actionTypes.SEND_FORM_OBJECT:
      return action.payload;
    default:
      return state;
  }
}
