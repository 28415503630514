import React, { Component } from "react";
import * as appJs from "../../js/app";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as staticActions from "../../redux/actions/staticActions";
import alertify from "alertifyjs";

class ModalPrediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      selectScore: null,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.props.handleKey);
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.props.handleKey);
    document.body.style.overflow = "auto";
  }

  changeSelected = (e) => {
    this.setState({
      selection: e,
      selectScore: null,
    });
  };

  changeScoreSelected = (e) => {
    this.setState({
      selectScore: e,
    });
  };

  addToBasket = (match) => {
    let matchData = {
      match_id: match.id,
      bet_type: this.state.selection,
    };

    if (this.state.selectScore !== null) {
      matchData = {
        match_id: match.id,
        bet_type: this.state.selection,
        home_score:
          this.state.selectScore !== "Diğer"
            ? parseInt(this.state.selectScore.split("-")[0])
            : 32,
        away_score:
          this.state.selectScore !== "Diğer"
            ? parseInt(this.state.selectScore.split("-")[1])
            : 32,
      };
    }

    if (this.state.selection !== null) {
      if (this.state.selection === 4 && this.state.selectScore === null) {
        // console.error("olmaz");
      } else {
        this.props.actions.addToBasket(matchData, this.props.token).then(() => {
          if (this.props.basket.status === 200) {
            window.location.reload();
          } else if (this.props.basket.status === 403) {
            alertify.alert(
              "Eklenemedi!",
              this.props.basket.result,
              function () {
                window.location.reload();
              }
            );
          }
          // console.log(this.props.basket.status);
        });
      }
    } else {
      // console.error("olmaz");
    }
  };

  render() {
    const { match, closeModal, handleKey } = this.props;
    const scores = [
      "0-0",
      "0-1",
      "0-2",
      "0-3",
      "0-4",
      "0-5",
      "0-6",
      "1-0",
      "1-1",
      "1-2",
      "1-3",
      "1-4",
      "1-5",
      "2-0",
      "2-1",
      "2-2",
      "2-3",
      "2-4",
      "3-0",
      "3-1",
      "3-2",
      "3-3",
      "3-4",
      "4-0",
      "4-1",
      "4-2",
      "4-3",
      "5-0",
      "5-1",
      "6-0",
      "Diğer",
    ];

    return (
      <div className="modal_prediction">
        <div className="match">
          <span className="modal_close" onClick={closeModal}>
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
              </svg>
            </figure>
          </span>
          <div className="match_content">
            <h5 className="h_5">{appJs.matchFormatDate(match.date)}</h5>
            <div className="teams">
              <div className="team_names left">
                <h6 className="team h_8">{match.home}</h6>
                <span className="vs">VS</span>
                <h6 className="team h_8 right">{match.away}</h6>
              </div>
              <div className="teams_last_matches">
                <div className="team_last_matches left">
                  <p className="b_4">Son 5 Maç</p>
                  <div className="data">
                    {appJs
                      .formatLastMatch(
                        match.home_last_5_matches !== null
                          ? match.home_last_5_matches
                          : "NNNNN"
                      )
                      .map((match, i) => (
                        <span className={"match_info " + match} key={i}>
                          <span className="modal non_select b_4">
                            {match === "W"
                              ? "Kazanan"
                              : match === "L"
                              ? "Kaybeden"
                              : match === "D"
                              ? "Berabere"
                              : "Maç Verisi Yok"}
                          </span>
                        </span>
                      ))}
                  </div>
                </div>
                <div className="team_last_matches right">
                  <p className="b_4">Son 5 Maç</p>
                  <div className="data">
                    {appJs
                      .formatLastMatch(
                        match.away_last_5_matches !== null
                          ? match.away_last_5_matches
                          : "NNNNN"
                      )
                      .map((match, i) => (
                        <span className={"match_info " + match} key={i}>
                          <span className="modal non_select b_4">
                            {match === "W"
                              ? "Kazanan"
                              : match === "L"
                              ? "Kaybeden"
                              : match === "D"
                              ? "Berabere"
                              : "Maç Verisi Yok"}
                          </span>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider">
            <hr />
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
              </svg>
            </figure>
            <hr />
          </div>
          <div className="predictions_and_scores">
            <div className="predictions">
              <span
                className={
                  "option outline_btn b_3 non_select " +
                  (this.state.selection === 1 ? "active" : "")
                }
                onClick={() => this.changeSelected(1)}
              >
                Ev
              </span>
              <span
                className={
                  "option outline_btn b_3 non_select " +
                  (this.state.selection === 2 ? "active" : "")
                }
                onClick={() => this.changeSelected(2)}
              >
                Berabere
              </span>
              <span
                className={
                  "option outline_btn b_3 non_select " +
                  (this.state.selection === 3 ? "active" : "")
                }
                onClick={() => this.changeSelected(3)}
              >
                Deplasman
              </span>
              <span
                className={
                  "option outline_btn b_3 non_select " +
                  (this.state.selection === 4 ? "active" : "")
                }
                onClick={() => this.changeSelected(4)}
              >
                Skor
              </span>
            </div>
            {this.state.selection === 4 ? (
              <div className="scores">
                {scores.map((score, i) => (
                  <span
                    className={
                      "option outline_btn b_1 non_select " +
                      (this.state.selectScore === score ? "active" : "")
                    }
                    onClick={() => this.changeScoreSelected(score)}
                    key={i}
                  >
                    {score}
                  </span>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <button
            className="background_btn non_select"
            onClick={() => this.addToBasket(match)}
          >
            <span className="btn-loader"></span>
            <p className="b_3">Pakete Ekle</p>
          </button>
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      addToBasket: bindActionCreators(predictionActions.addToBasket, dispatch),
      // getMatches: bindActionCreators(dispatch),
      // addToPredictions: bindActionCreators(
      //   staticActions.addMatchToPredictions,
      //   dispatch
      // ),
    },
  };
}

function mapStateToProps(state) {
  return {
    // matches: state.matchesListReducer,
    // predictions: state.predictionsReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrediction);
