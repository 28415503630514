import React, { Component } from "react";

export default class FAQs extends Component {
  toggleOpen = (e) => {
    e.currentTarget.classList.toggle("open");
    let p = e.currentTarget.querySelector("p.hood");
    if (e.currentTarget.classList.contains("open")) {
      p.classList.add("h_8");
      p.classList.remove("b_1");
    } else {
      p.classList.remove("h_8");
      p.classList.add("b_1");
    }
  };

  render() {
    return (
      <div className="faqs">
        <div className="main-hood">
          <h2 className="h_2">S.S.S.</h2>
          <h6 className="h_6">Sıkça Sorulan Sorular</h6>
        </div>
        <div className="questions-category">
          <h6 className="h_7 non_select">Genel Sorular</h6>
          <div className="questions">
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>1M1 nedir?</p>
                <p className="b_1 open-txt non_select">
                  1M1, futbol maç sonuçlarını tahmin ederek puan
                  kazanabileceğiniz ve ödüller alabileceğiniz bir platformdur.
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>Nasıl üye olabilirim?</p>
                <p className="b_1 open-txt non_select">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Voluptas, placeat.
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="questions-category">
          <h6 className="h_7 non_select">Üyelik ve Hesap Soruları</h6>
          <div className="questions">
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>
                  Üyelik planları nelerdir?
                </p>
                <p className="b_1 open-txt non_select">
                  Ücretsiz, M1, M2 ve M3 olmak üzere dört farklı üyelik planı
                  sunmaktayız. Ücretli üyelikler size daha fazla avantaj sağlar.
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>
                  Üyeliğimi nasıl yükseltebilirim?
                </p>
                <p className="b_1 open-txt non_select">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Voluptas, placeat.
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>
                  Hesabımı nasıl kapatabilirim?
                </p>
                <p className="b_1 open-txt non_select">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Voluptas, placeat.
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="questions-category">
          <h6 className="h_7 non_select">TAHMİN VE PUANLAMA</h6>
          <div className="questions">
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>
                  Puanlar nasıl hesaplanır?
                </p>
                <p className="b_1 open-txt non_select">
                  Maç sonuçlarını doğru tahmin ederek puan kazanırsınız. Skoru
                  tam doğru
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
            <div className="question " onClick={this.toggleOpen}>
              <div className="content">
                <p className={"hood non_select b_1"}>
                  Haftalık tahmin yapmazsam ne olur?
                </p>
                <p className="b_1 open-txt non_select">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Voluptas, placeat.
                </p>
              </div>
              <div className="arrow">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
