import $ from "jquery";

$(window).on("resize", function () {
  registerOverflow();
});

$(window).on("scroll", function () {
  var scrollPos = $(window).scrollTop();
  var maxScroll = $(document).height() - $(window).height();

  if (scrollPos >= 0 && scrollPos <= maxScroll) {
    $("body").css({
      "background-position": `${0 - scrollPos / 10}px ${
        0 + scrollPos / 10
      }px, ${250 + scrollPos / 10}px ${250 + scrollPos / 10}px`,
    });
  }
});

export function matchFormatDate(input) {
  const date = new Date(input);

  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${hours}:${minutes}`;
}

export function formatLastMatch(match = "NNNNN") {
  let resultArray = match.split("");
  for (let i = resultArray.length; i < 5; i++) {
    resultArray.push("N");
  }

  return resultArray;
}

export function formatMatchSelection(input, home, away) {
  switch (input) {
    case 1:
      return "Ev";
    case 2:
      return "Berabere";
    case 3:
      return "Deplasman";
    case 4:
      if (home === 32) {
        return "Diğer";
      }
      return home + "-" + away;
    default:
      return "-";
  }
}

export function registerOverflow() {
  if (
    window.location.pathname.split("/")[1] === "register" ||
    window.location.pathname.split("/")[1] === "login"
  ) {
    const container = document.getElementById("overflow-register");
    if (container.scrollHeight > container.clientHeight) {
      $("#overflow-register").css({ justifyContent: "flex-start" });
    } else {
      $("#overflow-register").css({ justifyContent: "center" });
    }
  }
}

export function tokenControl(token) {
  if (token !== null && token !== "" && typeof token !== "number") {
    return true;
  } else {
    return false;
  }
}

export function browserControl() {
  if (
    window.chrome !== null &&
    (window.navigator.userAgent.indexOf("OPR") > -1 ||
      window.navigator.userAgent.indexOf("Opera") > -1)
  ) {
    document.documentElement.classList.add("opera");
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    document.documentElement.classList.add("edge");
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    document.documentElement.classList.add("chrome");
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    document.documentElement.classList.add("safari");
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    document.documentElement.classList.add("firefox");
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    document.documentElement.classList.add("explorer");
  } else if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime) &&
    navigator.userAgent.indexOf("Edg") !== -1
  ) {
    document.documentElement.classList.add("edge_chromium");
  }
}
