import React, { Component } from "react";
import { connect } from "react-redux";

class Detail extends Component {
  render() {
    return (
      <div>
        <h1>Match Detail</h1>
        <p>
          {this.props.match.home +
            " : " +
            this.props.match.home_score +
            "  |  " +
            this.props.match.away_score +
            " : " +
            this.props.match.away}
        </p>
        <h6>{this.props.match.result}</h6>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    match: state.matchDetailReducer,
  };
}

export default connect(mapStateToProps)(Detail);
