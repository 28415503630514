import React, { Component } from "react";
import { Link } from "react-router-dom";
import Leagues from "../leagues/Leagues";
import MainAdsorImg from "../components/MainAdsorImg";
import EmailInput from "./EmailInput";

export default class Homepage extends Component {
  render() {
    return (
      <div className="homepage">
        <MainAdsorImg />
        {/* <EmailInput /> */}
        <Leagues />
      </div>
    );
  }
}
