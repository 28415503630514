import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchActions from "../../redux/actions/matchActions";
import initialState from "../../redux/reducers/initialState";
import MiniPreload from "../preload/MiniPreload";
import ScrollToTop from "../scrollToTop/ScrollToTop";

class Leagues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    if (this.props.leagues.length <= 0) {
      this.props.actions.getLeagues();
    }
  }

  leagueDesc(id) {
    switch (id) {
      case 1:
        return "Fenerbahçe, Galatasary, Beşiktaş, Trabzonspor, Başakşehir ve daha fazlası";
      case 2:
        return "Arsenal, Chelsea, Liverpool, Manchester City, Manchester United ve daha fazlası";
      case 3:
        return "PSG, Lille, Lens, Monaco, Lyon, Bres, Rennes, Marsellie ve daha fazlası";
      case 4:
        return "FC Bayern Münih, Leipzig, SC Freiburg, Bayer 04 Leverkusen ve daha fazlası";
      case 5:
        return "Braga, Porto, Rio Ave, Estoril, Benfica, Farence, Boavista ve daha fazlası";
      case 6:
        return "AC Milan, AS Roma, Atalanta, Bologna, Empoli, Fiorentina ve daha fazlası";
      case 7:
        return "Athletico Madrid, Barcelona FC, Real Madrid, Sevilla FC ve daha fazlası";
      case 8:
        return "Genk, Gent, Standard Liege, Mechelen, Sint-Truiden ve daha fazlası";
      case 9:
        return "Olympiacos, Panathinaikos, Panionios F.C, Niki Volos, AEK, PAOK ve daha fazlası";
      case 10:
        return "Krasnodar, Lokomotiv Moscow, Zenit, Spartak Moskova ve daha fazlası";
      case 11:
        return "Arsenal , Monaco, Milan , PSG, Real Madrid, Juventus, Bologna ve daha fazlası";
      case 12:
        return "Porto, Beşiktaş JK, Ajax, Fenerbahçe, Frankfurt, Roma, Slavia Praha ve daha fazlası";
      default:
        return "";
    }
  }

  leaguesRender(league) {
    return (
      <div className="league" key={league.id}>
        <div className="content_league">
          <figure>
            <img
              // src={initialState.url + "/media/" + league.flag}
              src={"/media/" + league.flag}
              alt={league.name + " Flag"}
            />
          </figure>
          <h6 className="h_8">{league.name}</h6>
          <p className="b_2">{this.leagueDesc(league.id)}</p>
        </div>
        <Link
          to={"/matches?" + league.name.replace(/ /g, "") + "=" + league.id}
          reloadDocument
          className="background_btn b_1"
        >
          Görüntüle
        </Link>
      </div>
    );
  }

  render() {
    // const { refresh } = this.props;

    return (
      <div
        className={"leagues_component " + (this.state.open ? "all_league" : "")}
      >
        <div className="hood_league">
          <h5 className="h_5">Ligler</h5>
          <Link to="/leagues" className="outline_btn non_select">
            {/* <span
            className="outline_btn non_select"
            onClick={() =>
              this.setState((prevState) => ({
                open: !prevState.open,
              }))
            }
          > */}
            <p className="h_8 non_select">Tüm Ligleri Görüntüle</p>
            {/* </span> */}
          </Link>
        </div>
        <div className="leagues">
          {this.props.leagues.length <= 0 ? (
            <MiniPreload />
          ) : (
            this.props.leagues
              .slice(0, this.state.open ? this.props.leagues.length : 4)
              .map((league) => this.leaguesRender(league))
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getLeagues: bindActionCreators(matchActions.getLeagueList, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    leagues: state.leagueListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
