import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import * as userActions from "../../redux/actions/userActions";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRedirect: false,
    };
  }
  componentDidMount() {
    // console.log(!localStorage.getItem("token"));
    if (!localStorage.getItem("token")) {
      this.setState({ shouldRedirect: true });
    } else {
      this.props.actions.getUserDetails(localStorage.getItem("token"));
    }
  }

  render() {
    if (this.state.shouldRedirect) {
      return <Navigate to="/login" />;
    }
    return (
      <div>
        <h1>User Details - {this.props.userDetails.id}</h1>
        <p>{this.props.userDetails.username}</p>
        <p>{this.props.userDetails.email}</p>
        <p>{this.props.userDetails.tckn}</p>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.getUserDetailsReducer,
    token: state.registerorLoginReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
