import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function selectPhoneCodeReducer(
  state = initialState.selectedPhoneCode,
  action
) {
  switch (action.type) {
    case actionTypes.SELECT_PHONE_CODE:
      return action.payload;
    default:
      return state;
  }
}
