import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function resetPasswordCodeReducer(
  state = initialState.resetPasswordCodeEndTime,
  action
) {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_CODE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
