import React, { Component } from "react";
import VerificationInput from "react-verification-input";

export default class VerifyCodeInput extends Component {
  // componentDidMount() {
  //   if ("OTPCredential" in window) {
  //     window.addEventListener("DOMContentLoaded", (e) => {
  //       const input = document.querySelector(
  //         'input[autocomplete="one-time-code"]'
  //       );
  //       if (!input) return;
  //       const ac = new AbortController();
  //       const form = input.closest("form");
  //       if (form) {
  //         form.addEventListener("submit", (e) => {
  //           ac.abort();
  //         });
  //       }
  //       navigator.credentials
  //         .get({
  //           otp: { transport: ["sms"] },
  //           signal: ac.signal,
  //         })
  //         .then((otp) => {
  //           input.value = otp.code;
  //           if (form) form.submit();
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     });
  //   }
  // }
  // componentDidUpdate() {
  //   if ("OTPCredential" in window) {
  //     window.addEventListener("DOMContentLoaded", (e) => {
  //       const input = document.querySelector(
  //         'input[autocomplete="one-time-code"]'
  //       );
  //       if (!input) return;
  //       const ac = new AbortController();
  //       const form = input.closest("form");
  //       if (form) {
  //         form.addEventListener("submit", (e) => {
  //           ac.abort();
  //         });
  //       }
  //       navigator.credentials
  //         .get({
  //           otp: { transport: ["sms"] },
  //           signal: ac.signal,
  //         })
  //         .then((otp) => {
  //           input.value = otp.code;
  //           if (form) form.submit();
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     });
  //   }
  // }
  render() {
    const { value, onChange } = this.props;

    return (
      <div className="verify-code-inputs">
        {/* <input type="text" inputMode="numeric" autoComplete="one-time-code" /> */}
        <VerificationInput
          validChars="0-9"
          inputProps={{ inputMode: "numeric" }}
          length={5}
          placeholder=" "
          // autocomplete="one-time-code"
          value={value} // Değer, parent state'den alınır
          onChange={onChange} // Değişiklikler parent'a aktarılır
        />
      </div>
    );
  }
}
