import React, { Component } from "react";
import LeaguesDropdown from "./LeaguesDropdown";
import TeamsDropdown from "./TeamsDropdown";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";

class FavTeamAndLeague extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoad: false,
      isRedirect: false,
    };
  }

  send = (e) => {
    e.preventDefault();
    // let favTeam = null;
    const data = {
      username: this.props.form_obj.username,
      email: this.props.form_obj.mail,
      phone_number: this.props.form_obj.last_phone,
      password: this.props.form_obj.password,
      campaign_notifications: "false",
      recaptcha_token: this.props.form_obj.recaptcha_token,
      fav_team_id:
        this.props.selectedTeam.id !== null ? this.props.selectedTeam.id : null,
    };
    if (
      e.nativeEvent.submitter.id === "send" &&
      this.props.selectedTeam.id === null
    ) {
      // console.log("gonderilmez");
    } else {
      this.props.actions
        .register(data)
        .then(() => {
          // console.log(this.props.token);
          this.setState({ isRedirect: true });
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  };

  render() {
    if (this.state.isRedirect) {
      return <Navigate to="/" />;
    }

    return (
      <div className="fav-team-and-league">
        <form onSubmit={this.send}>
          <LeaguesDropdown />
          <hr />
          <TeamsDropdown />
          <div className="btns">
            <button
              type="submit"
              id="send"
              className="background_btn"
              disabled={this.state.btnLoad ? true : false}
              data-loader={this.state.btnLoad ? true : false}
            >
              <span className="btn-loader"></span>
              <h4 className="b_1">Devam et</h4>
            </button>
            <button type="submit" id="skip" className="outline_btn">
              <h4 className="b_1">Bu sekmeyi atla</h4>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      // sendFormObject: bindActionCreators(userActions.sendFormObject, dispatch),
      register: bindActionCreators(userActions.register, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    selectedTeam: state.selectTeamReducer,
    form_obj: state.sendFormObjectReducer,
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavTeamAndLeague);
