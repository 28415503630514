import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Form from "./forgotPassword/Form";
import VerifyCodePage from "./forgotPassword/VerifyCodePage";
import NotFound from "../pages/NotFound";
import SetPassword from "./forgotPassword/SetPassword";

export default class ForgotPassword extends Component {
  componentDidMount() {
    document.getElementsByClassName("main-hood")[0].style.display = "none";
  }

  render() {
    return (
      <div className="forgot-password">
        <h4 className="h_6">Şifremi Unuttum</h4>
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path="verify_code" element={<VerifyCodePage />} />
          <Route path="set_password" element={<SetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  }
}
