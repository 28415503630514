import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withRouter } from "../withRouter";
import * as matchActions from "../../redux/actions/matchActions";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as appJs from "../../js/app";
import MainAdsorImg from "../components/MainAdsorImg";
import Leagues from "../leagues/Leagues";
import MiniPreload from "../preload/MiniPreload";
import ModalPrediction from "../modals/ModalPrediction";

class Matches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedMatch: null,
    };
  }

  componentDidMount() {
    // console.log(this.props.basket);
    if (window.location.search.split("=")[1] !== undefined) {
      this.props.actions
        .getMatches(window.location.search.split("=")[1])
        .then(() => {
          // console.warn(this.props.matches.matches);
        });
      this.props.actions.getBasket(this.props.token).then(() => {
        // console.warn(this.props.basket.result.basket);
      });
    }
  }

  openModal = (match) => {
    this.setState({
      isModalOpen: true,
      selectedMatch: match,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      selectedMatch: null,
    });
  };

  handleKeyDownCloseModal = (e) => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  render() {
    if (window.location.search.split("=")[1] === undefined) {
      return <Navigate to="/" />;
    }

    const { isModalOpen, selectedMatch } = this.state;

    return (
      <div className="matches_page">
        {isModalOpen && (
          <ModalPrediction
            match={selectedMatch}
            handleKey={this.handleKeyDownCloseModal}
            closeModal={this.closeModal}
          />
        )}
        <MainAdsorImg />
        <div className="matches_filter"></div>
        {this.props.matches.status !== 200 &&
        this.props.basket.status !== 200 ? (
          <MiniPreload />
        ) : this.props.matches &&
          this.props.matches.matches &&
          this.props.matches.matches.length > 0 ? (
          <div className="matches_content">
            <h5 className="h_5 hood">{this.props.matches.matches[0].league}</h5>
            <div className="matches">
              {this.props.matches.matches.map((match) => (
                <div
                  className={
                    "match " +
                    (this.props.basket.status === 200 &&
                    this.props.basket.result.basket.length > 0
                      ? this.props.basket.result.basket.some(
                          (matchP) => matchP.match_id === match.id
                        )
                        ? "added"
                        : ""
                      : "")
                  }
                  key={match.id}
                >
                  <div className="match_content">
                    <h5 className="h_5">{appJs.matchFormatDate(match.date)}</h5>
                    <div className="teams">
                      <div className="team_names left">
                        <h6 className="team h_8">{match.home}</h6>
                        <span className="vs">VS</span>
                        <h6 className="team h_8 right">{match.away}</h6>
                      </div>
                      <div className="teams_last_matches">
                        <div className="team_last_matches left">
                          <p className="b_4">Son 5 Maç</p>
                          <div className="data">
                            {appJs
                              .formatLastMatch(
                                match.home_last_5_matches !== null
                                  ? match.home_last_5_matches
                                  : "NNNNN"
                              )
                              .map((match, i) => (
                                <span className={"match_info " + match} key={i}>
                                  <span className="modal non_select b_4">
                                    {match === "W"
                                      ? "Kazanan"
                                      : match === "L"
                                      ? "Kaybeden"
                                      : match === "D"
                                      ? "Berabere"
                                      : "Maç Yapılmamış"}
                                  </span>
                                </span>
                              ))}
                          </div>
                        </div>
                        <div className="team_last_matches right">
                          <p className="b_4">Son 5 Maç</p>
                          <div className="data">
                            {appJs
                              .formatLastMatch(
                                match.away_last_5_matches !== null
                                  ? match.away_last_5_matches
                                  : "NNNNN"
                              )
                              .map((match, i) => (
                                <span className={"match_info " + match} key={i}>
                                  <span className="modal non_select b_4">
                                    {match === "W"
                                      ? "Kazanan"
                                      : match === "L"
                                      ? "Kaybeden"
                                      : match === "D"
                                      ? "Berabere"
                                      : "Maç Verisi Yok"}
                                  </span>
                                </span>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="background_btn"
                    onClick={() => this.openModal(match)}
                    disabled={
                      this.props.basket.status === 200 &&
                      this.props.basket.result.basket.some(
                        (matchP) => matchP.match_id === match.id
                      )
                        ? true
                        : false
                    }
                  >
                    <span className="btn-loader"></span>
                    <p className="b_3">
                      {this.props.basket.status === 200 &&
                      this.props.basket.result.basket.some(
                        (matchP) => matchP.match_id === match.id
                      )
                        ? this.props.basket.result.basket.map((matchP) =>
                            matchP.match_id === match.id
                              ? appJs.formatMatchSelection(
                                  matchP.bet_type,
                                  matchP.home_score,
                                  matchP.away_score
                                )
                              : null
                          )
                        : "Tahmin Yap"}
                    </p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="matches_content no">
            <h3 className="h_3">Burada Henüz Maç Görünmüyor!</h3>
            <h6 className="h_8">Başka Maçlara Bakmaya Ne Dersin?</h6>
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </figure>
          </div>
        )}

        {this.props.matches.status === 200 &&
        this.props.matches.matches.length > 0 ? (
          <MainAdsorImg />
        ) : (
          <></>
        )}
        {this.props.matches.status === 200 ? <Leagues /> : <></>}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getMatches: bindActionCreators(matchActions.getUpcomingMatches, dispatch),
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    matches: state.matchesListReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    // predictions: state.predictionsReducer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Matches));
