import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import VerifyCodeInput from "../verifyCode/VerifyCodeInput";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";

class VerifyCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoad: false,
      timeLeft: this.props.verify_code_time,
      // timeLeft: 50,
      code: null,
      isAuthenticated: false,
      isWrong: null,
    };
    this.interval = null;
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.clearTimer(); // Bileşen unmount olduğunda interval temizlenir
  }

  startTimer = () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.timeLeft > 0) {
            return { timeLeft: prevState.timeLeft - 1 }; // 1 saniye azalt
          } else {
            this.clearTimer(); // Süre dolunca timer durdurulur
            return { timeLeft: 0 };
          }
        });
      }, 1000);
    }
  };

  // Timer'ı durduran fonksiyon
  clearTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  handleInputChange = (value) => {
    this.setState({ code: value });
  };

  send = (e) => {
    e.preventDefault();
    // console.log(this.props.form_obj);
    this.setState({ btnLoad: true });
    if (this.props.form_obj.mail !== "") {
      this.props.actions
        .code_email_verify(this.props.form_obj.mail, this.state.code)
        .then(() => {
          this.setState({
            isAuthenticated: this.props.verify_code === "true" ? true : false,
            isWrong: this.props.verify_code !== "true" ? true : false,
            btnLoad: false,
          });
        });
    } else if (this.props.form_obj.last_phone !== "") {
      this.props.actions
        .code_phone_verify(this.props.form_obj.last_phone, this.state.code)
        .then(() => {
          this.setState({
            isAuthenticated: this.props.verify_code === "true" ? true : false,
            isWrong: this.props.verify_code !== "true" ? true : false,
            btnLoad: false,
          });
        });
    }
  };

  sendCodeAgain = (e) => {
    e.preventDefault();
    if (this.state.timeLeft <= 0) {
      if (this.props.form_obj.mail !== "") {
        this.props.actions
          .verifyEmailCode(this.props.form_obj.mail)
          .then(() => {
            this.setState({
              timeLeft: this.props.verify_code_time,
            });
            this.startTimer();
          });
      } else if (this.props.form_obj.last_phone !== "") {
        this.props.actions
          .verifyPhoneCode(this.props.form_obj.last_phone)
          .then(() => {
            this.setState({
              timeLeft: this.props.verify_code_time,
            });
            this.startTimer();
          });
      }
    } else {
      // console.log("zaman bitmemis");
    }
  };

  render() {
    // Dakika ve saniyeye dönüştürme
    const minutes = Math.floor(this.state.timeLeft / 60); // Dakika hesaplama
    const seconds = this.state.timeLeft % 60; // Kalan saniyeyi hesaplama

    if (this.state.isAuthenticated) {
      return <Navigate to="/register/favorite_league_and_team" />;
    }

    return (
      <div
        className={"verify-code-page " + (this.state.isWrong ? "wrong" : "")}
      >
        <form>
          <VerifyCodeInput
            value={this.state.code}
            onChange={this.handleInputChange}
          />
          <div className="wrong-txt">
            <p className="h_8">Kodu Yanlış Girdiniz</p>
          </div>
          <div className="content-verify">
            <div className="content-verify-inner phone">
              <p className="b_1">
                Telefon numaranızı doğrulamanız, hesabınızın güvenliğini
                artırmak ve kişisel bilgilerinizi korumak için önemli bir
                adımdır. Bu sayede, sadece size özel erişim sağlanır ve yetkisiz
                girişler engellenir.
              </p>
              <div className="links">
                <Link to=".." className="b_2">
                  Telefon Numarasını Değiştir
                </Link>
                <button
                  type="submit"
                  onClick={this.sendCodeAgain}
                  disabled={this.state.timeLeft <= 0 ? false : true}
                  className="b_2"
                >
                  Tekrar Mesaj Gönder
                </button>
              </div>
            </div>
            <p className="counter b_2">
              Kalan Süre: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          </div>
          <button
            type="submit"
            onClick={this.send}
            className="background_btn"
            disabled={
              this.state.timeLeft <= 0 || this.state.btnLoad ? true : false
            }
            data-loader={this.state.btnLoad ? true : false}
          >
            <span className="btn-loader"></span>
            <h4 className="b_1">Devam et</h4>
          </button>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      code_email_verify: bindActionCreators(userActions.verifyEmail, dispatch),
      code_phone_verify: bindActionCreators(userActions.verifyPhone, dispatch),
      verifyEmailCode: bindActionCreators(
        userActions.verifyEmailCode,
        dispatch
      ),
      verifyPhoneCode: bindActionCreators(
        userActions.verifyPhoneCode,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    verify_code_time: state.verificationPhoneorMailReducer,
    form_obj: state.sendFormObjectReducer,
    verify_code: state.verifyPhoneorMailReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCode);
