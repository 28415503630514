import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function PageScrollToTop({ targetId }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollTo(0, 0);
      }
    }
  }, [pathname, targetId]);

  return null;
}
