import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function canBeUsedReducer(
  state = initialState.canBeUsed,
  action
) {
  switch (action.type) {
    case actionTypes.EMAIL_CONTROL:
      return {
        ...state,
        email: action.payload,
      };
    case actionTypes.PHONE_CONTROL:
      return {
        ...state,
        phone: action.payload,
      };
    case actionTypes.USERNAME_CONTROL:
      return {
        ...state,
        username: action.payload,
      };
    default:
      return state;
  }
}
