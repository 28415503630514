import React, { Component } from "react";

export default class MainAdsorImg extends Component {
  render() {
    return (
      <figure className="main_ads_or_img">
        <img src="/img/adImg.png" alt="" />
      </figure>
    );
  }
}
