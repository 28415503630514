import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <h2 className="h_2">Gizlilik Politikası</h2>
        <div className="policies">
          <div className="policy">
            <h6 className="h_8">GİRİŞ</h6>
            <p className="b_1">
              1M1 olarak gizliliğinize saygı duyuyoruz ve kişisel verilerinizin
              korunmasını önemsiyoruz. Bu Gizlilik Politikası, platformumuzu
              kullanırken sağladığınız bilgilerin nasıl toplandığını,
              kullanıldığını ve korunduğunu açıklar. Platformumuzu kullanarak bu
              politikayı kabul ettiğinizi onaylamış olursunuz.
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">TOPLANAN VERİLER</h6>
            <h6 className="h_8">
              Platformumuzu kullanırken şu veriler toplanabilir:
            </h6>
            <div className="desc">
              <p className="b_1">
                <span>Kişisel Bilgiler: </span>
                Ad, soyad, e-posta adresi, telefon numarası gibi bilgiler.
              </p>
              <p className="b_1">
                <span>Teknik Bilgiler: </span>
                IP adresi, cihaz bilgileri, tarayıcı türü, çerezler ve kullanım
                alışkanlıkları.
              </p>
              <p className="b_1">
                <span>Kullanıcı Etkileşimleri: </span>
                Tahminleriniz, kazandığınız puanlar ve site üzerindeki
                aktiviteleriniz.
              </p>
            </div>
          </div>
          <div className="policy half">
            <h6 className="h_8">GİRİŞ</h6>
            <p className="b_1">
              1M1 olarak gizliliğinize saygı duyuyoruz ve kişisel verilerinizin
              korunmasını önemsiyoruz. Bu Gizlilik Politikası, platformumuzu
              kullanırken sağladığınız bilgilerin nasıl toplandığını,
              kullanıldığını ve korunduğunu açıklar. Platformumuzu kullanarak bu
              politikayı kabul ettiğinizi onaylamış olursunuz.
            </p>
          </div>
          <div className="policy">
            <h6 className="h_8">GİRİŞ</h6>
            <p className="b_1">
              1M1 olarak gizliliğinize saygı duyuyoruz ve kişisel verilerinizin
              korunmasını önemsiyoruz. Bu Gizlilik Politikası, platformumuzu
              kullanırken sağladığınız bilgilerin nasıl toplandığını,
              kullanıldığını ve korunduğunu açıklar. Platformumuzu kullanarak bu
              politikayı kabul ettiğinizi onaylamış olursunuz.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
