import React from "react";
import ReactDOM from "react-dom/client";
import App from "./jsx/root/App";
import configureStore from "./redux/reducers/configureStore";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "alertifyjs/build/css/alertify.min.css";
import "./css/main.css";
import "./js/app";

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>{" "}
  </BrowserRouter>
);
